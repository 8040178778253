/* GLOBAL */
* {
  margin: 0;
  padding: 0;
  outline: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5vh;
  font-size: 14px;
  background-image: url("./backlbc.jpeg");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html,
body,
#root {
  height: 100%;
}

.container {
  position: relative;
  max-height: 350px;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

/* .content {
  position: relative;
  display: block;
  max-width: 600px;
  margin: 30px;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
} */

input[type="file"] {
  display: none;
}

.upload {
  padding: 20px 10px;
  max-width: 200px;
  font-family: sans-serif;

  border: 1px solid rgb(58, 57, 57);
  border-radius: 4px;
  cursor: pointer;

  transition: height 0.2s ease;
}

.upload:hover {
  color: #3f51b5;
  border-color: #3f51b5;
  font-weight: bold;
}

.upload2 {
  padding: 10px;
  max-width: 300px;
  font-family: sans-serif;

  border: 1px solid rgb(58, 57, 57);
  border-radius: 4px;
  cursor: pointer;

  transition: height 0.2s ease;
  align-self: center;
}

.upload2:hover {
  color: #3f51b5;
  border-color: #3f51b5;
  font-weight: bold;
}

input[type="submit"] {
  background-color: #3f51b5;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover {
  background-color: #303f9f;
}

p {
  font-family: sans-serif;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 20px;
}

span {
  font-size: 10px;
}

a {
  background-color: #c83e3e;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  margin-left: 15px;
}
a:hover {
  background-color: #a73939;
}
